body{
    font-family: 'Roboto', sans-serif!important;
}

i{
    font-style: normal;
}

#etabl{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.date_cal{
  color:  rgb(0,44,66);
  font-size: 20px;
    margin-left: 5px!important;
}

.menu_carte{
    color:  rgb(0,44,66);
    font-size: 20px;
    margin: 0px!important;
    padding: 0px!important;

}

#inputcouv{
    text-align: center;
    width: 40px;
}

.ico-blue{
    color:  rgb(0,44,66);
    font-size: 20px;
}

abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
    font-size: 10px!important;
}

.buttonTileHour{
    min-width: 64px!important;
    min-height: 40px!important;
}

.buttonTileHour:hover{
    background-color: #e6e6ee;
    /*color: purple!important;*/
    cursor: pointer;
}


.MuiInputBase-input-133{
    font-size: 15px!important;
    margin-left: 5px!important;
}

.MuiTooltip-tooltip-100{
    background-color: white!important;
    border: 1px rgb(0,44,66) solid;
    border-radius: 5%;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.activated-bt-hrs{
    background-color: rgb(0,44,66)!important;
    color: white!important;
    border: none!important;
}

.activated-bt-hrs:hover{
    background-color: #e6e6ee! important;
    /*color: purple!important;*/
}

.activated-bt-couv{
    background-color: rgb(0,44,66) !important;
    color: white!important;
    border: none!important;
}

.bg-desc{
    background-color: #81C784;
    border-radius: 5px;
    color: white;
    padding: 10px 5px;
}

.bg-desc  p{
    padding: 0px;
}

.bg-desc-unavailable{
    background-color: #F05D5E;
    border-radius: 5px;
    color: white;
}

.activated-cb-hrs{
    background-color: rgb(0,44,66)!important;
    color: white!important;
}
.activated-cb-hrs div{
    background-color: rgb(0,44,66)!important;
    color: white!important;
    border-color: rgb(0,44,66)!important;
}

.active-hr-item{
    color: rgb(0,44,66)!important;
}

.activated-cb-hrs svg{
    color: white!important;
}



.MuiButtonBase-root-241{
    color: rgb(0,44,66)!important;
}

.card_top{
    margin-top: 60px!important;
}

h1{
    margin-bottom: 0px;
    font-weight: lighter;
}

h2{
    margin-top: 0px;
    color: rgb(0,44,66);
    font-weight: normal;
}

.nextBtn{
    padding-bottom: 10px!important;
    padding-right: 10px!important;
    margin-left: 0!important;
}

.no_margin_left{
    margin-left: 0px!important;
}


.dangerously_styled{
    /*color:  #E1E1E1;*/
    font-size: 15px;
}


.dangerously_styled *{
    /*color:  #E1E1E1;*/
    max-width: 100% !important;
}

.dangerous-desc > *{
    color: white!important;
    text-align: left;
    padding: 3px 3px 3px 10px;
}


.icon-arrow{
    cursor: pointer;
}

/* new */
#resPaper{
    padding: 10px;
}

.sectionStyle{
    margin-bottom: 10px!important;
    width: 100%;
}

.sectionBodyStyle{
    margin-bottom: 5px!important;
}

.info_input{
    padding: 0px !important;
}

.MuiGrid-spacing-xs-8-23{
    width: 100%!important;
}



.lang-ic{
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.activated-bt-couv:hover{
    background-color: #e6e6ee !important;
    /*color: purple!important;*/
}

.calendar input {
    cursor: pointer;
}

/* loader */

.lds-roller {
    margin-left: 5px;
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
}
.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 16px 16px;
}
.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: rgb(0,44,66);
    margin: -1px 0 0 -1px;
}
.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
    top: 25px;
    left: 25px;
}
.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
    top: 27px;
    left: 23px;
}
.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
    top: 28px;
    left: 20px;
}
.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
    top: 29px;
    left: 16px;
}
.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
    top: 28px;
    left: 13px;
}
.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
    top: 27px;
    left: 10px;
}
.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
    top: 25px;
    left: 7px;
}
.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
    top: 22px;
    left: 5px;
}
@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.infoIcons{
    background-color: white;
    font-size: 12px;
    color: lightgrey;
    position: absolute;
    border-radius: 5px;
    right: -30px;
    top: 10px;
    padding: 5px;
    border: 1px solid #A3A3A3;
}

.activated-info-icon{
    background-color: rgb(0,44,66);
    color: white;
}

.paperBlur{
    filter: blur(2px);
}
