@font-face {
    font-family: 'fontello';
    src: url('../font/fontello.eot?11543458');
    src: url('../font/fontello.eot?11543458#iefix') format('embedded-opentype'),
    url('../font/fontello.woff2?11543458') format('woff2'),
    url('../font/fontello.woff?11543458') format('woff'),
    url('../font/fontello.ttf?11543458') format('truetype'),
    url('../font/fontello.svg?11543458#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?11543458#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-arrow:before { content: '\e800'; } /* '' */
.icon-calendar:before { content: '\e801'; } /* '' */
.icon-civilité:before { content: '\e802'; } /* '' */
.icon-mail:before { content: '\e803'; } /* '' */
.icon-close:before { content: '\e804'; } /* '' */
.icon-password:before { content: '\e805'; } /* '' */
.icon-portable:before { content: '\e806'; } /* '' */
.icon-receive_message:before { content: '\e807'; } /* '' */
.icon-reservation_couverts:before { content: '\e808'; } /* '' */
.icon-simple_user:before { content: '\e809'; } /* '' */
.icon-time:before { content: '\e80a'; } /* '' */
.icon-stars:before { content: '\e80b'; } /* '' */
.icon-user:before { content: '\e80c'; } /* '' */
.icon-validate:before { content: '\e80d'; } /* '' */
.icon-position:before { content: '\e80e'; } /* '' */
.icon-commun_paiement:before { content: '\e80f'; } /* '' */
