h2, p {
  color: rgb(0,44,66);
  text-align: center;
  font-weight: 400;
}

label{
  color: rgb(0,44,66);
}
a{
  color: rgb(0,44,66);
}

.icon-mail, .icon-lock, .icon-civilité, .icon-user, .icon-portable, .icon-receive_message{
  margin-right: 5px;
  color: rgb(0,44,66);
}

.icon-lock{
  padding-left:2px;
}

.icon-validate{
  border-radius: 100%;
  height: 10px;
  width: 10px;
  background-color: #81C784;
  color: white;
  padding: 8px;
  font-size: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.icon-close{
  border-radius: 100%;
  background-color: #F05D5E;
  height: 10px;
  width: 10px;
  color: white;
  padding: 8px;
  font-size: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.border-separation{
  border-top: 1px solid rgb(0,44,66);
}

.small-margins-horizontal{
  margin-left: 10px;
  margin-right: 10px;
}

.lol{
  text-align: center;
}

#email-text{
  margin-left: 5px;
}

#lab-mdp-oubli{
  text-align: center;
}

.email-recognized{
  transform: rotate(90deg);
  background-color:#81C784;
}

.Mui-focused{
  box-shadow: 0px 0px 5px rgb(0,44,66);
}

.mentions{
  text-align: left;
  font-size: 11px;
  padding-left: 5px;
}

.mention{
  padding-left: 5px;
  text-align: left;
  color: rgb(0,44,66);
}


.mentions_error{
  color: #F05D5E;
  font-size: 15px;
}

.colorError{
  color: #F05D5E;
}

.border_colorError{
    border-color: #F05D5E;
}

.lastDivElem:first-child{
  margin-bottom: 10px;
}

.labelMarginBottom{
  margin-bottom: 5px;
}

.error_input {
  color: #F05D5E;
  margin-bottom: 5px ;
}

.social-i{
    margin-right: 10px;
    padding: 10px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.fa-google{
    border: 1px solid white;
}

.fa-facebook-f{
  background-color: #3c5a99;
  color: white;
}
.error_input_tel{
    color: #F05D5E;
    margin-bottom: 5px ;
    font-size: 10px;
}

.cooldown{
  background-color: grey;
  cursor: default;
  color: lightgray;
}




