h2, p {
  color: rgb(0,44,66);
  text-align: center;
  font-weight: 400;
}

label{
  color: rgb(0,44,66);
}
a{
  color: rgb(0,44,66);
}

.icon-mail, .icon-lock, .icon-civilité, .icon-user, .icon-portable, .icon-receive_message{
  margin-right: 5px;
  color: rgb(0,44,66);
}

.icon-lock{
  padding-left:2px;
}

.icon-validate{
  border-radius: 100%;
  height: 10px;
  width: 10px;
  background-color: #81C784;
  color: white;
  padding: 8px;
  font-size: 8px;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.icon-close{
  border-radius: 100%;
  background-color: #F05D5E;
  height: 10px;
  width: 10px;
  color: white;
  padding: 8px;
  font-size: 8px;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.border-separation{
  border-top: 1px solid rgb(0,44,66);
}

.small-margins-horizontal{
  margin-left: 10px;
  margin-right: 10px;
}

.lol{
  text-align: center;
}

#email-text{
  margin-left: 5px;
}

#lab-mdp-oubli{
  text-align: center;
}

.email-recognized{
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  background-color:#81C784;
}

.Mui-focused{
  box-shadow: 0px 0px 5px rgb(0,44,66);
}

.mentions{
  text-align: left;
  font-size: 11px;
  padding-left: 5px;
}

.mention{
  padding-left: 5px;
  text-align: left;
  color: rgb(0,44,66);
}


.mentions_error{
  color: #F05D5E;
  font-size: 15px;
}

.colorError{
  color: #F05D5E;
}

.border_colorError{
    border-color: #F05D5E;
}

.lastDivElem:first-child{
  margin-bottom: 10px;
}

.labelMarginBottom{
  margin-bottom: 5px;
}

.error_input {
  color: #F05D5E;
  margin-bottom: 5px ;
}

.social-i{
    margin-right: 10px;
    padding: 10px;
    font-size: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
}

.fa-google{
    border: 1px solid white;
}

.fa-facebook-f{
  background-color: #3c5a99;
  color: white;
}
.error_input_tel{
    color: #F05D5E;
    margin-bottom: 5px ;
    font-size: 10px;
}

.cooldown{
  background-color: grey;
  cursor: default;
  color: lightgray;
}





@font-face {
    font-family: 'fontello';
    src: url(/static/media/fontello.32a3bfd5.eot);
    src: url(/static/media/fontello.32a3bfd5.eot#iefix) format('embedded-opentype'),
    url(/static/media/fontello.815988c3.woff2) format('woff2'),
    url(/static/media/fontello.b5454d4e.woff) format('woff'),
    url(/static/media/fontello.f0d01264.ttf) format('truetype'),
    url(/static/media/fontello.861774b0.svg#fontello) format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?11543458#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-arrow:before { content: '\E800'; } /* '' */
.icon-calendar:before { content: '\E801'; } /* '' */
.icon-civilité:before { content: '\E802'; } /* '' */
.icon-mail:before { content: '\E803'; } /* '' */
.icon-close:before { content: '\E804'; } /* '' */
.icon-password:before { content: '\E805'; } /* '' */
.icon-portable:before { content: '\E806'; } /* '' */
.icon-receive_message:before { content: '\E807'; } /* '' */
.icon-reservation_couverts:before { content: '\E808'; } /* '' */
.icon-simple_user:before { content: '\E809'; } /* '' */
.icon-time:before { content: '\E80A'; } /* '' */
.icon-stars:before { content: '\E80B'; } /* '' */
.icon-user:before { content: '\E80C'; } /* '' */
.icon-validate:before { content: '\E80D'; } /* '' */
.icon-position:before { content: '\E80E'; } /* '' */
.icon-commun_paiement:before { content: '\E80F'; } /* '' */

body{
    font-family: 'Roboto', sans-serif!important;
}

i{
    font-style: normal;
}

#etabl{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.date_cal{
  color:  rgb(0,44,66);
  font-size: 20px;
    margin-left: 5px!important;
}

.menu_carte{
    color:  rgb(0,44,66);
    font-size: 20px;
    margin: 0px!important;
    padding: 0px!important;

}

#inputcouv{
    text-align: center;
    width: 40px;
}

.ico-blue{
    color:  rgb(0,44,66);
    font-size: 20px;
}

abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
    font-size: 10px!important;
}

.buttonTileHour{
    min-width: 64px!important;
    min-height: 40px!important;
}

.buttonTileHour:hover{
    background-color: #e6e6ee;
    /*color: purple!important;*/
    cursor: pointer;
}


.MuiInputBase-input-133{
    font-size: 15px!important;
    margin-left: 5px!important;
}

.MuiTooltip-tooltip-100{
    background-color: white!important;
    border: 1px rgb(0,44,66) solid;
    border-radius: 5%;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.activated-bt-hrs{
    background-color: rgb(0,44,66)!important;
    color: white!important;
    border: none!important;
}

.activated-bt-hrs:hover{
    background-color: #e6e6ee! important;
    /*color: purple!important;*/
}

.activated-bt-couv{
    background-color: rgb(0,44,66) !important;
    color: white!important;
    border: none!important;
}

.bg-desc{
    background-color: #81C784;
    border-radius: 5px;
    color: white;
    padding: 10px 5px;
}

.bg-desc  p{
    padding: 0px;
}

.bg-desc-unavailable{
    background-color: #F05D5E;
    border-radius: 5px;
    color: white;
}

.activated-cb-hrs{
    background-color: rgb(0,44,66)!important;
    color: white!important;
}
.activated-cb-hrs div{
    background-color: rgb(0,44,66)!important;
    color: white!important;
    border-color: rgb(0,44,66)!important;
}

.active-hr-item{
    color: rgb(0,44,66)!important;
}

.activated-cb-hrs svg{
    color: white!important;
}



.MuiButtonBase-root-241{
    color: rgb(0,44,66)!important;
}

.card_top{
    margin-top: 60px!important;
}

h1{
    margin-bottom: 0px;
    font-weight: lighter;
}

h2{
    margin-top: 0px;
    color: rgb(0,44,66);
    font-weight: normal;
}

.nextBtn{
    padding-bottom: 10px!important;
    padding-right: 10px!important;
    margin-left: 0!important;
}

.no_margin_left{
    margin-left: 0px!important;
}


.dangerously_styled{
    /*color:  #E1E1E1;*/
    font-size: 15px;
}


.dangerously_styled *{
    /*color:  #E1E1E1;*/
    max-width: 100% !important;
}

.dangerous-desc > *{
    color: white!important;
    text-align: left;
    padding: 3px 3px 3px 10px;
}


.icon-arrow{
    cursor: pointer;
}

/* new */
#resPaper{
    padding: 10px;
}

.sectionStyle{
    margin-bottom: 10px!important;
    width: 100%;
}

.sectionBodyStyle{
    margin-bottom: 5px!important;
}

.info_input{
    padding: 0px !important;
}

.MuiGrid-spacing-xs-8-23{
    width: 100%!important;
}



.lang-ic{
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.activated-bt-couv:hover{
    background-color: #e6e6ee !important;
    /*color: purple!important;*/
}

.calendar input {
    cursor: pointer;
}

/* loader */

.lds-roller {
    margin-left: 5px;
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
}
.lds-roller div {
    -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    -webkit-transform-origin: 16px 16px;
            transform-origin: 16px 16px;
}
.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: rgb(0,44,66);
    margin: -1px 0 0 -1px;
}
.lds-roller div:nth-child(1) {
    -webkit-animation-delay: -0.036s;
            animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
    top: 25px;
    left: 25px;
}
.lds-roller div:nth-child(2) {
    -webkit-animation-delay: -0.072s;
            animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
    top: 27px;
    left: 23px;
}
.lds-roller div:nth-child(3) {
    -webkit-animation-delay: -0.108s;
            animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
    top: 28px;
    left: 20px;
}
.lds-roller div:nth-child(4) {
    -webkit-animation-delay: -0.144s;
            animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
    top: 29px;
    left: 16px;
}
.lds-roller div:nth-child(5) {
    -webkit-animation-delay: -0.18s;
            animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
    top: 28px;
    left: 13px;
}
.lds-roller div:nth-child(6) {
    -webkit-animation-delay: -0.216s;
            animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
    top: 27px;
    left: 10px;
}
.lds-roller div:nth-child(7) {
    -webkit-animation-delay: -0.252s;
            animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
    top: 25px;
    left: 7px;
}
.lds-roller div:nth-child(8) {
    -webkit-animation-delay: -0.288s;
            animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
    top: 22px;
    left: 5px;
}
@-webkit-keyframes lds-roller {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes lds-roller {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.infoIcons{
    background-color: white;
    font-size: 12px;
    color: lightgrey;
    position: absolute;
    border-radius: 5px;
    right: -30px;
    top: 10px;
    padding: 5px;
    border: 1px solid #A3A3A3;
}

.activated-info-icon{
    background-color: rgb(0,44,66);
    color: white;
}

.paperBlur{
    -webkit-filter: blur(2px);
            filter: blur(2px);
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

